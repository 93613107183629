/* Responsive Images and Gallery style */
.responsive-image {
  width: 100%;
}
#gallery {
  width: 100%;
}
#gallery__image-edit-container,
#gallery__image-active {
  width: 100%;
}
.thumbnail-image {
  cursor: pointer;
  margin: 10px;
  padding-bottom: 20px;
}
#gallery__image-edit__sidebar > div.form-group > * {
  width: 100%;
  margin-bottom: 3px;
}
#gallery__image-edit__sidebar > div.form-group > .input-group > label.input-group-addon {
  width: 80px;
}
#gallery__image-edit--preview-wrapper {
  overflow: hidden;
}
#gallery__image-edit--log {
  vertical-align: middle;
}
.gallery-message {
  padding: 4px 4px 4px 15px;
  width: 100%;
  border-radius: 5px;
  font-size: medium;
}
.dashboard-gallery-tag {
  display: inline-block;
  margin-top: 10px;
}
/* Dashboard image widget stuff */
#image-selection-wrapper {
  display: none;
}
#image-upload-wrapper {
  display: none;
}
#single-image-field-thumbnail {
  margin-top: 5px;
  margin-bottom: 5px;
  display: block;
}
.image-selection-thumbnail {
  margin: 0 auto;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-bottom: 15px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow: auto;
  background-image: linear-gradient(0deg, #f6f6f6, #fefefe);
}
.image-selection-thumbnail-image {
  box-sizing: border-box;
  float: left;
  width: 40%;
  padding: 10px;
}
.image-selection-thumbnail-image img {
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #ccc;
}
.image-selection-thumbnail-text {
  box-sizing: border-box;
  float: left;
  width: 60%;
  padding: 10px;
}
.image-selection-thumbnail-text h4.image-title {
  font-size: 16px;
  color: #333;
  font-weight: bold;
  margin-bottom: 5px;
  line-height: 18px;
  vertical-align: middle;
}
.image-selection-thumbnail-text .image-timestamp {
  margin: 0;
  font-size: 11px;
  color: #888;
  font-style: italic;
}
.image-selection-thumbnail-text .image-description {
  color: #666;
  font-size: 12px;
  margin-top: 5px;
  line-height: 12px;
}
.image-selection-thumbnail-active {
  border-color: #66afe9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
#gallery__upload-button,
#gallery__edit-button {
  text-align: center;
  text-decoration: none;
  display: block;
  -webkit-box-shadow: 0 3px 12px rgba(0, 0, 0, 0.075);
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.075);
  min-width: 200px;
  max-width: 200px;
  min-height: 75px;
  max-height: 75px;
  color: #555;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px;
  margin: 0 auto 5px;
  cursor: pointer;
}
.gallery__unhandled-image {
  margin-right: 15px;
  margin-bottom: 15px;
}
#gallery__image-active {
  max-width: 100%;
}
#gallery__thumbnail-view img {
  cursor: pointer;
}
